import '@mrhenry/wp--bugsnag-config';

/* Modules */
import './modules/accordion';
import './modules/input-sink';
import './modules/navigation-overlay';

import { initMenuButtons } from '@mrhenry/wp--mr-interactive';

initMenuButtons();
